import { FC } from 'react';
import { Box, Footer, Group, Text } from '@mantine/core';
import { appSections } from '@/constants/appSections';
import Section from '@/ui/Section/Section';
import Telegram from '@/assets/inline-svg/Telegram.svg';
import Metro from '@/assets/inline-svg/Metro.svg';
import Walker from '@/assets/images/walker.png';

import { useStyles } from './styles';

const LayoutFooter: FC = () => {
  const { classes } = useStyles();

  return (
    <Footer className={classes.root} withBorder={false} height={473}>
      <Section id={appSections.contacts.id} bg="#FFF8F8" containerProps={{ maw: 1020 }}>
        <Section.Title>Контакты</Section.Title>

        <Group className={classes.row}>
          <Box className={classes.contacts}>
            <a className={classes.contact} href="https://t.me/agilehub_ru">
              <Telegram />
              <mark>agilehub_ru</mark>
            </a>
            <a className={classes.contact} href="tel:+74994905038">
              +7 499 490 50 38
            </a>
            <a className={classes.contact} href="mailto:info@agilehub.ru">
              info@agilehub.ru
            </a>
          </Box>

          <Box className={classes.address}>
            <Text className={classes.addressTitle}>Москва, дизайн-завод Флакон</Text>
            <Group className={classes.addressGroup}>
              <Box className={classes.addressInfo}>
                <Metro /> Дмитровская
              </Box>
              <Box className={classes.addressInfo}>
                <img src={Walker} alt="" width={18} height={22} /> 5 минут
              </Box>
            </Group>
            <Text>ул. Большая Новодмитровская, д. 36, стр. 12, вход 6</Text>
          </Box>
        </Group>

        <Box className={classes.copy}>© 2002-2023, Agile Hub</Box>
      </Section>
    </Footer>
  );
};

export default LayoutFooter;
