import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 60,
    paddingBottom: 60,

    [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
      paddingTop: 50,
      paddingBottom: 50,
    },
    [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
      paddingTop: 30,
      paddingBottom: 30,
    },
  },

  title: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: '0.5em',
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: 80,

    [`@media (max-width: ${theme.breakpoints.lg - 1}px)`]: {
      marginBottom: 65,
    },
    [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
      marginBottom: 50,
    },
    [`@media (max-width: ${theme.breakpoints.sm - 1}px)`]: {
      marginBottom: 30,
    },
  },
}));
