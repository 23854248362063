import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    background: 'none',
    height: 473,
    maxHeight: 'none',

    [`@media (max-width: ${theme.breakpoints.lg - 1}px)`]: {
      height: 459,
    },
    [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
      textAlign: 'center',
      height: 637,
    },
    [`@media (max-width: ${theme.breakpoints.sm - 1}px)`]: {
      height: 632,
    },
    [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
      height: 544,
    },
  },

  row: {
    alignItems: 'flex-start',
    justifyContent: 'space-between',

    [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'initial',
      gap: 60,
      marginTop: 70,
    },
    [`@media (max-width: ${theme.breakpoints.sm - 1}px)`]: {
      marginTop: 65,
    },
    [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
      gap: 31,
      marginTop: 60,
    },
  },

  contacts: {
    flexGrow: 1,
    maxWidth: 372,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 20,

    [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
      alignItems: 'center',
    },
    [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
      gap: 11,
    },
  },

  contact: {
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: 38,
    lineHeight: '42px',
    whiteSpace: 'nowrap',
    color: theme.black,

    [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
      fontWeight: 400,
      fontSize: 36,
      lineHeight: '45px',
    },

    svg: {
      marginRight: 15,
    },

    mark: {
      color: 'inherit',
      backgroundColor: '#EDBAFE',
      padding: '2px 10px',
    },
  },

  address: {
    fontSize: 18,
    flexGrow: 1,
    maxWidth: 372,
  },

  addressTitle: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: 1,
    marginBottom: 33,

    [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
      marginBottom: 23,
    },
    [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
      fontSize: 18,
      lineHeight: 1.93,
      marginBottom: 26,
    },
  },

  addressGroup: {
    gap: 32,
    marginLeft: -4,

    [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
      justifyContent: 'center',
      gap: 14,
    },
  },

  addressInfo: {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },

  copy: {
    fontSize: 18,
    marginTop: 53,

    [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
      marginTop: 35,
    },
    [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
      marginTop: 16,
    },
  },
}));
