import { MantineThemeOverride } from '@mantine/core';
import { BREAKPOINTS } from '@/constants/mantine/breakpoints';
import { COLORS } from '@/constants/mantine/colors';

export const appTheme: MantineThemeOverride = {
  breakpoints: BREAKPOINTS,
  fontFamily: 'Mulish',
  lineHeight: 1.93,
  fontSizes: {
    xs: 14,
    sm: 16,
    md: 18,
    lg: 20,
    xl: 22,
  },
  headings: {
    fontWeight: 500,
    fontFamily: 'Mulish',
    sizes: {
      h1: { fontSize: 40, lineHeight: 1.2 },
      h2: { fontSize: 36, lineHeight: 1.2, fontWeight: 400 },
      h3: { fontSize: 24, lineHeight: 1.2, fontWeight: 400 },
      h4: { fontSize: 18, lineHeight: 1.2, fontWeight: 400 },
      h5: { fontSize: 16, lineHeight: 1.2 },
      h6: { fontSize: 14, lineHeight: 1.4 },
    },
  },
  primaryColor: 'dark',
  cursorType: 'pointer',
  spacing: {
    xs: 16,
    sm: 20,
    md: 24,
    lg: 32,
    xl: 40,
  },

  globalStyles: (theme) => ({
    body: {
      [`@media (max-width: ${theme.breakpoints.sm - 1}px)`]: {
        fontSize: 16,
      },
    },
  }),

  components: {
    Container: {
      defaultProps: {
        size: 1380,
        px: 30,
      },
      styles: (theme) => ({
        root: {
          [`@media (max-width: ${theme.breakpoints.sm - 1}px)`]: {
            paddingLeft: 20,
            paddingRight: 20,
          },
          [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
            paddingLeft: 10,
            paddingRight: 10,
          },
        },
      }),
    },

    Button: {
      defaultProps: {
        size: 'md',
      },
      styles: (theme, params) => ({
        root: {
          fontWeight: 400,
          borderWidth: 3,
          borderRadius: 70,
          color: params.variant === 'default' ? theme.white : undefined,
          backgroundColor: params.variant === 'default' ? theme.black : undefined,
          borderColor: params.variant === 'default' ? theme.black : undefined,
          height: 53,
          minWidth: 240,

          '&:hover, &:active': {
            color: params.variant === 'default' ? theme.black : undefined,
            backgroundColor: params.variant === 'default' ? theme.white : undefined,
          },

          [`@media (max-width: ${theme.breakpoints.lg - 1}px)`]: {
            fontSize: 16,
            minWidth: 220,
          },
        },
        label: {
          whiteSpace: 'normal',
        },
      }),
    },

    Divider: {
      defaultProps: {
        color: COLORS.GRAY.border,
      },
    },

    Tabs: {
      styles: (theme) => ({
        tabsList: {
          flexWrap: 'nowrap',
          gap: 27,
          borderBottom: 'none',
          marginLeft: -9,
          marginRight: -9,

          [`@media (max-width: ${theme.breakpoints.lg - 1}px)`]: {
            justifyContent: 'center',
            gap: 18,
          },
          [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
            gap: 19,
            maxWidth: 530,
            marginLeft: 'auto',
            marginRight: 'auto',
          },
          [`@media (max-width: ${theme.breakpoints.sm - 1}px)`]: {
            maxWidth: 404,
          },
          [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
            gap: 22,
            flexDirection: 'column',
            alignItems: 'center',
          },

          '.mantine-Divider-root': {
            alignSelf: 'center',
            height: 47,
            marginBottom: 3,

            [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
              display: 'none',
            },
          },
        },
        tab: {
          fontSize: 24,
          lineHeight: '30px',
          whiteSpace: 'normal',
          textAlign: 'center',
          borderRadius: 0,
          borderBottomWidth: 3,
          padding: '0 9px 15px',
          marginBottom: 0,

          '&:hover': {
            backgroundColor: 'transparent',
          },

          '@media (hover: hover)': {
            '&:hover': {
              borderColor: 'transparent',
            },
          },

          [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
            padding: '0 0 10px',
          },
        },
        panel: {
          [`@media (max-width: ${theme.breakpoints.md - 1}px)`]: {
            textAlign: 'center',
            maxWidth: 620,
            marginLeft: 'auto',
            marginRight: 'auto',
          },
          [`@media (max-width: ${theme.breakpoints.sm - 1}px)`]: {
            maxWidth: 410,
          },
        },
      }),
    },
  },
};
